import styled from "styled-components";

export const ClientsReviewStyled = styled.div`
  margin-top: 100px;

  .client-information {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .clientsReview {
    background: var(--gradient-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 50px 55px;
  }

  .full-card {
    max-width: 600px;
    width: 100%;
    height: 350px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 34.9px 29.6px 48.3px 0 rgba(51, 102, 255, 0.05);
    border: solid 1px #e5f4f2;
    background-color: #fff;
    padding: 40px;
    gap: 30px;
    /* flex-wrap: wrap; */
  }

  .clientlable {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  .clienttitle {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    padding-bottom: 50px;
    margin: 0%;
    margin-top: 20px;
  }

  .client-card {
    max-width: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .client-name {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    color: #111827;
    padding-top: 16px;
    padding-bottom: 6px;
  }

  .client-email {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #374151;
  }

  .client-rate {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    align-items: baseline;
  }

  .rate {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 5px;
  }

  .client-description {
    max-width: 350px;
    width: 100%;
  }
  .slick-track {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .slick-dots{
    bottom: -36px;
  }
  .slick-dots li button:before{
    font-size: 12px;
    color: #fff;
  }
  @media (max-width: 1024px) {
    .full-card {
      display: block !important;
      height: 100%;
    }
    .slick-slide {
      margin: 0 1px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    .slick-list {
      height: 100%;
    }
    .client-card {
      max-width: unset;
    }
    .client-rate {
      align-items: center;
    }
  }
  @media (max-width: 600px) {
    .slick-slide {
      margin: 0px;
    }
    .client-card {
      max-width: unset;
    }
    .full-card {
      flex-wrap: wrap;
      height: 100%;
    }
    .clientsReview {
      padding: 0;
    }
    .client-rate {
      align-items: center;
    }
    .slick-dots{
      bottom: 0;
    }
    .slick-list {
      height: 100%;
    }
  }
  /* @media (max-width: 1340px) {
    .slick-slide {
      margin: 0 5px;
    }
  } */
`;
