import styled from "styled-components";
import DownArrow from "../../assets/downarrow.png";

export const PartnerForm = styled.div`
  position: relative;

  .formWrapper {
    max-width: 605px;
    width: 100%;
    background-color: #fff;
    margin: auto;
    position: relative;
    top: -120px;
    padding: 40px 70px;
    border-radius: 16px;
    box-shadow: 34.9px 29.6px 48.3px 0 rgba(51, 102, 255, 0.05);
  }
  .form-title .title {
    text-align: center;
    font-size: 38px;
    font-weight: 900;
    color: #2d2d2d;
    margin-bottom: 26px;
  }

  .formName,
  .formEmail,
  .formContact {
    width: 100%;
    margin-bottom: 10px;
    padding: 18px 20px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    outline: none;
  }
  .formMsg {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px 20px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    outline: none;
  }
  .formCity,
  .formState,
  .formCountry {
    margin-bottom: 10px;
    padding: 18px 20px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    outline: none;
    color: #757575;
    -webkit-appearance: none;
  }
  .main-select.open{
    rotate: 90deg;
  }
  .main-select {
    position: relative;
    width: 100%;
  }
  .customSelect {
    position: absolute;
    right: 10px;
    top: 34%;
  }
  .mutiField {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 10px;
    justify-content: space-between;
  }
  .formBtn {
    border: 0px;
    border-radius: 5px;
    background: var(--gradient-color);
    color: #fff;
    padding: 16px 30px;
    outline: none;
  }

  /* @media (min-width: 320px) {
    .formCity,
    .formState {
      width: 100%;
    }
    .formWrapper {
      padding: 20px 35px;
    }
  } */

  @media (max-width: 490px) {
    /* .formWrapper {
      padding: 40px 70px;
    } */
    .formCity,
    .formState,
    .formCountry {
      width: 100%;
    }
    .formWrapper {
      padding: 20px 35px;
    }
  }
  @media (min-width: 583px) {
    .formCity,
    .formState,
    .formCountry {
      width: auto;
    }
  }
  @media (max-width: 600px) {
    .mutiField {
      display: block;
    }
    .formCity,
    .formState,
    .formCountry {
      width: 100%;
    }
  }
  @media (min-width: 600px) {
    .formCity,
    .formState,
    .formCountry {
      max-width: 150px;
      width: 100%;
    }
  }
`;
