import React from "react";
import { ClientsReviewStyled } from "./styled";
import { clientsReview, reviewLable, reviewTitle } from "../../content/home";
import Slider from "react-slick";
const settings = {
     // variableWidth: true,
     infinite: true,
     slidesToShow: 2,
     speed: 800,
     cssEase: "linear",
     autoplay: true,
     slidesToScroll: 1,
     swipeToSlide: true,
     arrows: false,
     dots: true,
     responsive: [
          {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 1,
               },
          },
          {
               breakpoint: 750,
               settings: {
                    slidesToShow: 1,
               },
          },
          {
               breakpoint: 480,
               settings: {
                    slidesToShow: 1,
                    dots: false,
               },
          },
     ],
};

export default function Review() {
     return (
          <ClientsReviewStyled id="clients">
               <div className="clientsReview">
                    <div className="website-container">
                         <h1 className="clientlable">{reviewLable}</h1>
                         <p className="clienttitle">{reviewTitle}</p>

                         <Slider className="client-information" {...settings}>
                              {clientsReview.map((item, index) => {
                                   return (
                                        <div className="full-card" key={index}>
                                             <div className="client-card">
                                                  <img
                                                       className="client-img"
                                                       src={item.imgUrl}
                                                       alt=""
                                                       style={{ width: "120px", height: "120px" }}
                                                  />
                                                  <h1 className="client-name">{item.name}</h1>
                                                  {/* <h3 className="client-email">{item.email}</h3> */}
                                             </div>
                                             <div className="client-rate">
                                                  <div className="rate">
                                                       <img src={item.rateImg} alt="" />
                                                       <img src={item.rateImg} alt="" />
                                                       <img src={item.rateImg} alt="" />
                                                       <img src={item.rateImg} alt="" />
                                                       <img src={item.rateImg} alt="" />
                                                  </div>
                                                  <p className="client-description">{item.description}</p>
                                             </div>
                                        </div>
                                   );
                              })}
                         </Slider>
                    </div>
               </div>
          </ClientsReviewStyled>
     );
}
