import React from "react";
import { servicesData, urlImage } from "../../content/home";
import { CardStyled } from "./styled";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { serviceUrl } from "../../content/route";
const settings = {
     centerMode: true,
     variableWidth: true,
     infinite: true,
     slidesToShow: 1,
     speed: 800,
     cssEase: "linear",
     autoplay: true,
     slidesToScroll: 1,
     swipeToSlide: true,
};

export default function HomeCard() {
     const navigate = useNavigate();
     return (
          <div>
               <CardStyled>
                    <Slider className="card-container" {...settings}>
                         {servicesData.map((item, index) => (
                              <div className="card-wrapper" key={index}>
                                   <div className="image-wrapper">
                                        <img src={item.imgUrl} alt="" />
                                   </div>
                                   <div className="title">{item.content}</div>
                                   <div className="description">{item.descritpion}</div>
                                   <div className="btn-wrapper">
                                        <button className="learn-more-btn" onClick={() => navigate(serviceUrl)}>
                                             {item.button} <img src={item.arrow} alt="" />
                                        </button>
                                   </div>
                              </div>
                         ))}
                    </Slider>
                    <div className="layer-img">
                         <img src={urlImage} alt="" />
                    </div>
               </CardStyled>
          </div>
     );
}
