import React, { useState } from "react";
import { FirstTabination, TabinationStyled } from "./styled";
import { Feature, tabnation, tabs } from "../../content/product";
import HigherOrderComp from "../HigherOrderComp";
import ContactForm from "../ContactForm";

import closeBtn from "../../assets/Close.svg";

function Product() {
  const [activeTab, setActiveTab] = useState(tabnation[0]);
  const [open, setOpen] = useState(false);

  const handleTabination = (index) => {
    setActiveTab(index);
  };
  const handleOpen = () => {
    if (tabnation[0] === activeTab) {
      setOpen(true);
    } else {
      document.body.style.overflow = "auto";
      setOpen(false);
    }
  };
  const handleClose = () => {
    document.body.style.overflow = "auto";
    setOpen(false);
  };

  return (
    <>
      <div className="website-container">
        <TabinationStyled>
          {tabnation?.map((i, index) => (
            <div className="tab-wrapper" key={index}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`divider ${
                  activeTab === i ? "active text-gradient" : ""
                }`}
                onClick={() => handleTabination(i)}
              >
                {i}
              </a>
            </div>
          ))}
        </TabinationStyled>
      </div>
      <FirstTabination>
        <PopUp open={open} handleClose={handleClose} />
        <div className="website-container">
          <div className="card-wrapper">
            {tabs[activeTab]?.map((item, index) => (
              <div className="tab">
                <div
                  className="tabCard"
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    document.body.style.overflow = "hidden";
                    handleOpen();
                  }}
                >
                  <img
                    className="mainCardImg"
                    src={item.icons}
                    alt={item.title}
                  />
                  <h2 className="cardTitle">{item.title}</h2>
                  <p className="cardDescription">{item.discription}</p>
                  <div>
                    {item.button}{" "}
                    <img
                      className="arrowButton"
                      src={item.buttonIcon}
                      width={15}
                      height={15}
                      alt={item.title}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </FirstTabination>

      <ContactForm />
    </>
  );
}

export default HigherOrderComp(Product);

const PopUp = ({ open = false, handleClose }) => {
  // console.log(tabnation[0],"[][][][");
  return (
    <>
      {open && (
        <div className="popup-main" onClick={handleClose}>
          <div style={{ padding: "0px 30px" }}>
            <div className="website-container popup-container">
              <div className="popup-close">
                <img
                alt="Close Button"
                  src={closeBtn}
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="popup-title">
                <span
                  className="text-gradient"
                  style={{
                    fontSize: "38px",
                    fontWeight: 600,
                    marginBottom: "56px",
                  }}
                >
                  Features
                </span>
              </div>
              <div className="popup-cards">
                {Feature.map((item, index) => {
                  return (
                    <div
                      className="tabCard"
                      style={{ cursor: "pointer", height: "auto" }}
                      key={index}
                    >
                      <img
                        className="mainCardImg"
                        src={item.icons}
                        alt={item.title}
                      />
                      <h2 className="cardTitle">{item.title}</h2>
                      <p className="cardDescription">{item.discription}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// import { useState } from "react";

// export function Component() {
//   const [openModal, setOpenModal] = useState(true);

//   console.log(openModal);
//   return (
//     <>
//       <Button onClick={() => setOpenModal(true)}>Toggle modal</Button>
//       <Modal show={openModal} onClose={() => setOpenModal(false)}>
//         <Modal.Header>Terms of Service</Modal.Header>
//         <Modal.Body>
//           <div className="space-y-6">
//             <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
//               With less than a month to go before the European Union enacts new consumer privacy laws for its citizens,
//               companies around the world are updating their terms of service agreements to comply.
//             </p>
//             <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
//               The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant
//               to ensure a common set of data rights in the European Union. It requires organizations to notify users as
//               soon as possible of high-risk data breaches that could personally affect them.
//             </p>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button onClick={() => setOpenModal(false)}>I accept</Button>
//           <Button color="gray" onClick={() => setOpenModal(false)}>
//             Decline
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }
