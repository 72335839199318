import React, { useEffect, useState } from "react";
import {
  AboutCompanyStyled,
  AboutUsStyled,
  CardStyled,
  ChephlonFoundersStyled,
  OurStoryStyled,
} from "./styled";
import {
  OurStoryContent,
  OurStoryContentBesideTitle,
  OurStoryLable,
  OurStoryTitle,
  OurTeam,
  cardContent,
  cardLable,
  companyImageUrl,
  companyStoryImage,
  contentBesideCard,
  foundersContent,
  foundersLable,
  foundersPost,
  foundersTitle,
  misionContent,
  misionLable,
  misionTitle,
  visionContent,
  visionLable,
  visionTitle,
} from "../../content/aboutUs";
import HigherOrderComp from "../HigherOrderComp";
import ContactForm from "../ContactForm";

import Slider from "react-slick";
const settings = {
  initialSlide: 0,
  centerMode: false,
  variableWidth: true,
  infinite: true,
  slidesToShow: 1,
  speed: 800,
  cssEase: "linear",
  autoplay: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  pauseOnHover: false,
};

const AboutUs = () => {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    // Ensure the first slide is always the first item
    setTeam(OurTeam);
  }, []);
  return (
    <AboutUsStyled>
      <div className="website-container">
        <AboutCompanyStyled>
          <div className="about-company">
            <div className="about-company-cardcontent">
              <card className="about-company-card">
                <div className="lable-wrapper">
                  <span class="about-text-lable text-gradient ">
                    {cardLable}
                  </span>
                </div>
                <p className="about-title">{cardContent}</p>
              </card>
              <p className="about-content">{contentBesideCard}</p>
            </div>

            <img alt="Company" className="company-img" src={companyImageUrl} />

            <div className="website-container company-mision-vision">
              <div className="our-mision">
                <span className="mision-lable text-gradient">
                  {misionLable}
                </span>
                <h1 className="mission-title about-common-title">
                  {misionTitle}
                </h1>
                <p className="mision-content">{misionContent}</p>
              </div>

              <div className="our-vision">
                <span className="vision-lable text-gradient">
                  {visionLable}
                </span>
                <h1 className="vision-title about-common-title">
                  {visionTitle}
                </h1>
                <p className="vision-content">{visionContent}</p>
              </div>
            </div>
          </div>
        </AboutCompanyStyled>

        

        <OurStoryStyled id="story">
          <div className="our-story">
            <div className="our-story-information">
              <span className="our-story-lable text-gradient">
                {OurStoryLable}
              </span>
              <p className="our-story-content">{OurStoryContent}</p>
            </div>

            <div className="our-story-wrapper website-container">
              <h1 className="our-story-title about-common-title">
                {OurStoryTitle}
              </h1>
              <p className="our-story-title-content">
                {OurStoryContentBesideTitle}
              </p>
            </div>
          </div>
        </OurStoryStyled>
      </div>

      <ContactForm />
    </AboutUsStyled>
  );
};

export default HigherOrderComp(AboutUs);
