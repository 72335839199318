import React from 'react'
import {Link} from 'react-router-dom'
import './OldUI.css'

const Footer = () => {
    return (
        <div >
          
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="footer-about">
                <h2>About Us</h2>
                <p>
                  Manufacturer and Designer of smart consumer electronic products.
                  We write stack for MCU and also write as well as host,
                  database server and API server in order to collect and store data.
                  We also provide our clients with business and process optimization tools. 
                </p>
                <br />
                <div className="row">
                <p>
                <h3 className='font-extrabold'>Registered Office</h3>
                  <i className="fa fa-map-marker-alt"></i>1052, New Cloth Market, <br />
                                      Kesar Bagh Road,<br />
                                      Indore (MP) - 452009,<br />
                                      INDIA</p>
                
                                      <p className='ml-5'>
                <h3 className='font-extrabold'>Manufacturing Unit</h3>
                  <i className="fa fa-map-marker-alt "></i>
                                      1<sup>st</sup> Floor, <br />
                                      256, New Cloth Market, <br />
                                      Kesar Bagh Road,<br />
                                      Indore (MP) - 452009,<br />
                                      INDIA</p>
                

                </div>
                <p><i className="fa fa-phone-alt"></i>+91 93409 82323</p>
                <p><i className="fa fa-envelope"></i>saumya@cephlon.tech</p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-link">
                    <h2>Useful Link</h2>
                    <Link to="/About">Our Story</Link>
                    <Link to="/Services">Our Services</Link>
                    <Link to="/Products">Our Products</Link>
                   
                    <Link to="/Contact">Contact Us</Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-link">
                    <h2>Useful Link</h2>
                    <Link to="/">Our Clients</Link>
                    <Link to="/">Clients Review</Link>
                    <Link to="/">Ongoing Customer</Link>
                    <Link to="/">Customer Support</Link>
                    <Link to="/">FAQs</Link>
                    <Link to="/">Site Map</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container copyright">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; <Link to="/">Cephlon Technology,</Link>, All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      
        </div>
    )
};
export default Footer;