import React from "react";
import Homeurl from "../../assets/homepage-img.png";

import {
     headingservices,
     homeContent,
     homeContent1,
     homeTitle,
     homeTitle1,
     oueHeading,
     heading,
     ourMetricData,
     subtitle,
     imgArray,
} from "../../content/home";
import HigherOrderComp from "../HigherOrderComp";
import HomeCard from "../HomeCard";
import Whychoose from "../Whychoose";
import Featured from "../Featured";

import Review from "../Review";
import { HomeStyled, OurMetricStyled, ServiceStyled } from "./styled";
import ContactForm from "../ContactForm";
import { useNavigate } from "react-router-dom";
import { aboutUrl } from "../../content/route";

const Home = () => {
     const navigate = useNavigate();
     return (
          <>
               <HomeStyled>
                    <div className="website-container">
                         <div>
                              <p className="home-pre text-gradient">{homeTitle}</p>
                              <h1 className="heading">{heading}</h1>
                         </div>

                         <div
                              className="about-content"
                              style={{
                                   display: "flex",
                                   alignItems: "center",
                                   justifyContent: "space-evenly",
                              }}
                         >
                              <div className="img-content">
                                   <div className="img-div">
                                        <img src={Homeurl} alt="img" />
                                   </div>
                                   <div className="home-content">
                                        <p className="content content-margin">{homeContent}</p>
                                        <p className="content">{homeContent1}</p>
                                        <button className="home-btn btn-hover" onClick={() => navigate(aboutUrl)}>
                                             LEARN MORE
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </HomeStyled>

               <OurMetricStyled>
                    <div className="our-metrics">
                         <div className="website-container">
                              <h1 className="metricHeading">{oueHeading}</h1>
                              <p className="subtitle">{subtitle}</p>
                              <div className="metrics-box">
                                   {ourMetricData.map((item, index) => (
                                        <div className="box" key={index}>
                                             <h2>{item.price}</h2>
                                             <p>{item.descritpion}</p>
                                        </div>
                                   ))}
                              </div>
                         </div>
                    </div>
               </OurMetricStyled>

               <ServiceStyled id="services">
                    <div>
                         <p className="home-title text-gradient">{homeTitle1}</p>
                         <h1 className="services-heading">{headingservices}</h1>
                    </div>
               </ServiceStyled>

               <HomeCard />
               <Whychoose />

               <Featured array={imgArray} />

               <Review />
               <ContactForm />
          </>
     );
};

export default HigherOrderComp(Home);
