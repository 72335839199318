// import React, { useEffect, useState } from "react";


export default function Featured({ array }) {
  // const [isRendered, setIsRendered] = useState(false);

  // useEffect(() => {
  //   setIsRendered(true);
  // }, []);

  return (
    <div>
      {/* <FeaturedStyled isRendered={isRendered}>
        <div>
          <h1 className="featured-heading">We Featured on</h1>
        </div>

        <Slider {...settings} className="img-div">
          {array.map((v, index) => {
            return (
              <div key={index} className="img-center">
                <img src={v} alt="" />
              </div>
            );
          })}
        </Slider>
      </FeaturedStyled> */}
    </div>
  );
}
