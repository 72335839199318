import styled from "styled-components";

export const NavbarStyled = styled.div`
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  width: 100%;
  z-index: 99;
  .main {
    max-width: 1240px;
    width: 100%;
    height: 72px;
    margin: auto;
    padding: 13px 30px;

    border-radius: 10px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;

    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    img{
      cursor: pointer;
    }
  }

  .mobile-menu-wrapper {
    display: none;
    position: relative;
    z-index: 100;
  }
  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 56px;

    a {
      color: black;
      position: relative;
      transition: all 0.3s ease-in-out;
      font-weight: 600;
      font-size: 14px;
      text-decoration: none;
    }
    .active {
      font-weight: 600;
      letter-spacing: unset;
    }
    a:hover {
      text-decoration: none;
    }
    a::before {
      width: 0%;
      content: "";
      position: absolute;
      left: 0;
      bottom: -4px;
      height: 1px;
      background: var(--gradient-color);
      transition: all 0.3s ease-in-out;
    }
    a::after {
      position: absolute;
      content: "";
      left: 0%;
      bottom: -4px;
      height: 0px;
      width: 0px;
      background: var(--purple-color);
      border-radius: 50%;
      transform: translateY(2px);
      transition: all 0.3s ease-in-out;
    }
    .active::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -4px;
      height: 1px;
      width: 72%;
      background: var(--gradient-color);
      transition: all 0.3s ease-in-out;
    }
    .active::after {
      position: absolute;
      content: "";
      left: 72%;
      bottom: -4px;
      height: 6px;
      width: 6px;
      background: var(--purple-color);
      border-radius: 50%;
      transform: translateY(2px);
      transition: all 0.3s ease-in-out;
    }
  }
  .btn {
    /* height: 46px; */
    position: relative;
    padding: 16px;
    border-radius: 5px;
    background: var(--gradient-color);
    color: white;
    max-width: 133px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 12px;
  } 
  .mobile-menu {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    position: absolute;
    top: 30px;
    right: -80px;
    transform: translateX(-50%);
    width: 160px;
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.05);
    z-index: 100;
  }
  .mobile-menu a {
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 10px 0;
    color: black;
  }
  .mobile-menu a:last-child {
    border: none;
  }
  @media (max-width: 950px) {
    .menu-wrapper,
    .btn {
      display: none;
    }
    .mobile-menu-wrapper,
    .mobile-menu-wrapper .btn {
      display: unset;
    }
    .mobile-menu-wrapper .btn {
      padding: 8px;
    }
  }
`;
