import styled from "styled-components";

export const FirstTabination = styled.div`
margin-top:100px;
  .tabCard {
    max-width: 390px;
    width: 100%;
    box-shadow: 34.85px 29.63px 48.34px -3px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 31px 38px 29px;
    /* justify-content: space-between; */
    gap: 15px;
  }
  .tabCard .mainCardImg {
    max-width: 136px;
    width: 100%;
    height: 136px;
    object-fit: contain;
  }
  .cardTitle {
    /* margin: 39px 0px 15px 0px; */
    font-size: 24px;
  }
  .cardDescription {
    text-align: center;
    margin-bottom: 43px;
    font-weight: 400;
    line-height: 29px;
  }
  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 34px;
    row-gap: 100px;
  }
  .buttonArrow {
    background-image: url(rightArrow);
    width: 50px;
    height: 50px;
  }
  .arrowButton {
    margin-left: 30px;
  }
  @media (min-width: 320px) {
    .card-wrapper {
      justify-content: center;
    }
  }

  @media (min-width: 1270px) {
    .card-wrapper {
      justify-content: unset;
    }
  }
`;