import React from "react";
import Navbar from "../Navbar/index";
import { HeaderStyled } from "./styled";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { contactUrl } from "../../content/route";

const Header = ({ isHomePage = true, title = "", subtitle = "", imageUrl = "", button = null }) => {
     const navigate = useNavigate();
     return (
          <HeaderStyled $isHomePage={`${isHomePage}`}>
               <Navbar />
               <img alt="header" className="header-img" src={imageUrl} />
               <div className="header-content website-container">
                    <motion.h1
                         className="title"
                         initial={{ opacity: 0, y: -200 }}
                         animate={{ opacity: 1, y: 0 }}
                         transition={{ duration: 0.5 }}
                    >
                         {title}
                    </motion.h1>
                    <motion.h5
                         className="subtitle"
                         initial={{ opacity: 0, y: -200 }}
                         animate={{ opacity: 0.7, y: 0 }}
                         transition={{ duration: 1, delay: 0.5 }}
                    >
                         {subtitle}
                    </motion.h5>
                    {button && (
                         <motion.button
                              className="home-btn btn-hover"
                              initial={{ opacity: 0, y: 100 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.8 }}
                              onClick={() => navigate(contactUrl)}
                         >
                              CONNECT WITH US
                         </motion.button>
                    )}
               </div>
          </HeaderStyled>
     );
};

export default Header;
