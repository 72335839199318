import React, { useState } from "react";
import { PartnerForm } from "./styled";
import Whychoose from "../Whychoose";
import Featured from "../Featured";
import { imgArray } from "../../content/home";
import Review from "../Review";
import HigherOrderComp from "../HigherOrderComp";
import DownArrow from "../../assets/downarrow.png";

function PartnerFormComp() {
  return (
    <PartnerForm>
      <div className="formWrapper">
        <div className="form-title">
          <h6 className="title">Become a Partner</h6>
        </div>
        <div className="form-fields">
          <input
            placeholder="Full Name"
            type="text"
            required
            className="formName"
          />
          <input placeholder="Email" type="text" className="formEmail" />
          <input placeholder="Contact Number" className="formContact" />
          <div className="mutiField">
            {/* <input placeholder="City" className="formCity" />
              <input placeholder="State" className="formState" /> */}
            {/* <select className="formCity">
                <option>Surat</option>
              </select> */}
            <Country />
          </div>
          <textarea placeholder="Send Message" rows={5} className="formMsg" />
          <button className="formBtn">Contact Us</button>
        </div>
      </div>
      <Whychoose />
      <Featured array={imgArray} />
      <Review />
    </PartnerForm>
  );
}

export default HigherOrderComp(PartnerFormComp);

const Country = () => {
  const countries = [
    {
      name: "United States",
      states: [
        { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"] },
        { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"] },
        { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"] },
        {
          name: "Arkansas",
          cities: ["Little Rock", "Fort Smith", "Fayetteville"],
        },
        {
          name: "California",
          cities: ["Los Angeles", "San Francisco", "San Diego"],
        },
        { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"] },
        {
          name: "Connecticut",
          cities: ["Bridgeport", "New Haven", "Hartford"],
        },
        { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"] },
        { name: "Florida", cities: ["Miami", "Orlando", "Tampa"] },
        { name: "Georgia", cities: ["Atlanta", "Savannah", "Augusta"] },
        { name: "Hawaii", cities: ["Honolulu", "Hilo", "Kailua"] },
        { name: "Idaho", cities: ["Boise", "Meridian", "Nampa"] },
        { name: "Illinois", cities: ["Chicago", "Aurora", "Naperville"] },
        {
          name: "Indiana",
          cities: ["Indianapolis", "Fort Wayne", "Evansville"],
        },
        { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"] },
        { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"] },
        {
          name: "Kentucky",
          cities: ["Louisville", "Lexington", "Bowling Green"],
        },
        {
          name: "Louisiana",
          cities: ["New Orleans", "Baton Rouge", "Shreveport"],
        },
        { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"] },
        { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"] },
        {
          name: "Massachusetts",
          cities: ["Boston", "Worcester", "Springfield"],
        },
        { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Ann Arbor"] },
        {
          name: "Minnesota",
          cities: ["Minneapolis", "Saint Paul", "Rochester"],
        },
        { name: "Mississippi", cities: ["Jackson", "Gulfport", "Biloxi"] },
        {
          name: "Missouri",
          cities: ["Kansas City", "Saint Louis", "Springfield"],
        },
        { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"] },
        { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"] },
        { name: "Nevada", cities: ["Las Vegas", "Reno", "Henderson"] },
        { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"] },
        { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"] },
        {
          name: "New Mexico",
          cities: ["Albuquerque", "Santa Fe", "Las Cruces"],
        },
        { name: "New York", cities: ["New York City", "Buffalo", "Rochester"] },
        {
          name: "North Carolina",
          cities: ["Charlotte", "Raleigh", "Greensboro"],
        },
        { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"] },
        { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"] },
        { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"] },
        { name: "Oregon", cities: ["Portland", "Eugene", "Salem"] },
        {
          name: "Pennsylvania",
          cities: ["Philadelphia", "Pittsburgh", "Allentown"],
        },
        { name: "Rhode Island", cities: ["Providence", "Warwick", "Cranston"] },
        {
          name: "South Carolina",
          cities: ["Charleston", "Columbia", "Greenville"],
        },
        {
          name: "South Dakota",
          cities: ["Sioux Falls", "Rapid City", "Aberdeen"],
        },
        { name: "Tennessee", cities: ["Nashville", "Memphis", "Knoxville"] },
        { name: "Texas", cities: ["Houston", "Dallas", "Austin"] },
        {
          name: "Utah",
          cities: ["Salt Lake City", "West Valley City", "Provo"],
        },
        {
          name: "Vermont",
          cities: ["Burlington", "South Burlington", "Rutland"],
        },
        { name: "Virginia", cities: ["Virginia Beach", "Norfolk", "Richmond"] },
        { name: "Washington", cities: ["Seattle", "Spokane", "Tacoma"] },
        {
          name: "West Virginia",
          cities: ["Charleston", "Huntington", "Morgantown"],
        },
        { name: "Wisconsin", cities: ["Milwaukee", "Madison", "Green Bay"] },
        { name: "Wyoming", cities: ["Cheyenne", "Casper", "Laramie"] },
      ],
    },
    {
      name: "United Kingdom",
      states: [
        { name: "England", cities: ["London", "Manchester", "Birmingham"] },
        { name: "Scotland", cities: ["Edinburgh", "Glasgow", "Aberdeen"] },
        { name: "Wales", cities: ["Cardiff", "Swansea", "Newport"] },
        { name: "Northern Ireland", cities: ["Belfast", "Derry", "Lisburn"] },
      ],
    },
    {
      name: "Canada",
      states: [
        { name: "Alberta", cities: ["Calgary", "Edmonton", "Red Deer"] },
        {
          name: "British Columbia",
          cities: ["Vancouver", "Victoria", "Surrey"],
        },
        { name: "Manitoba", cities: ["Winnipeg", "Brandon", "Steinbach"] },
        {
          name: "New Brunswick",
          cities: ["Fredericton", "Moncton", "Saint John"],
        },
        {
          name: "Newfoundland and Labrador",
          cities: ["St. John's", "Corner Brook", "Gander"],
        },
        { name: "Nova Scotia", cities: ["Halifax", "Sydney", "Dartmouth"] },
        { name: "Ontario", cities: ["Toronto", "Ottawa", "Mississauga"] },
        {
          name: "Prince Edward Island",
          cities: ["Charlottetown", "Summerside", "Stratford"],
        },
        { name: "Quebec", cities: ["Montreal", "Quebec City", "Laval"] },
        {
          name: "Saskatchewan",
          cities: ["Saskatoon", "Regina", "Prince Albert"],
        },
        {
          name: "Northwest Territories",
          cities: ["Yellowknife", "Hay River", "Inuvik"],
        },
        { name: "Nunavut", cities: ["Iqaluit", "Rankin Inlet", "Arviat"] },
        { name: "Yukon", cities: ["Whitehorse", "Dawson City", "Watson Lake"] },
      ],
    },
    {
      name: "Australia",
      states: [
        {
          name: "New South Wales",
          cities: ["Sydney", "Newcastle", "Wollongong"],
        },
        { name: "Victoria", cities: ["Melbourne", "Geelong", "Ballarat"] },
        { name: "Queensland", cities: ["Brisbane", "Gold Coast", "Cairns"] },
        {
          name: "South Australia",
          cities: ["Adelaide", "Mount Gambier", "Gawler"],
        },
        {
          name: "Western Australia",
          cities: ["Perth", "Fremantle", "Bunbury"],
        },
        { name: "Tasmania", cities: ["Hobart", "Launceston", "Devonport"] },
        {
          name: "Northern Territory",
          cities: ["Darwin", "Alice Springs", "Palmerston"],
        },
        {
          name: "Australian Capital Territory",
          cities: ["Canberra", "Queanbeyan", "Yass"],
        },
      ],
    },
    {
      name: "India",
      states: [
        { name: "Maharashtra", cities: ["Mumbai", "Pune", "Nagpur"] },
        { name: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
        { name: "Karnataka", cities: ["Bengaluru", "Mysore", "Mangalore"] },
        { name: "Gujarat", cities: ["Ahmedabad", "Surat", "Vadodara"] },
        { name: "West Bengal", cities: ["Kolkata", "Howrah", "Durgapur"] },
        { name: "Uttar Pradesh", cities: ["Lucknow", "Kanpur", "Ghaziabad"] },
        { name: "Rajasthan", cities: ["Jaipur", "Jodhpur", "Udaipur"] },
        { name: "Madhya Pradesh", cities: ["Bhopal", "Indore", "Gwalior"] },
        {
          name: "Andhra Pradesh",
          cities: ["Visakhapatnam", "Vijayawada", "Guntur"],
        },
        { name: "Telangana", cities: ["Hyderabad", "Warangal", "Nizamabad"] },
      ],
    },
    {
      name: "South Africa",
      states: [
        { name: "Gauteng", cities: ["Johannesburg", "Pretoria", "Soweto"] },
        {
          name: "Western Cape",
          cities: ["Cape Town", "Stellenbosch", "Paarl"],
        },
        {
          name: "KwaZulu-Natal",
          cities: ["Durban", "Pietermaritzburg", "Richards Bay"],
        },
        {
          name: "Eastern Cape",
          cities: ["Port Elizabeth", "East London", "Grahamstown"],
        },
        { name: "Mpumalanga", cities: ["Nelspruit", "Mbombela", "Witbank"] },
        { name: "Limpopo", cities: ["Polokwane", "Mokopane", "Thohoyandou"] },
        {
          name: "North West",
          cities: ["Rustenburg", "Mahikeng", "Klerksdorp"],
        },
        { name: "Free State", cities: ["Bloemfontein", "Welkom", "Mangaung"] },
      ],
    },
  ];
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [countryArrow, setCountryArrow] = useState(false);
  const [stateArrow, setStateArrow] = useState(false);
  const [cityArrow, setCityArrow] = useState(false);

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    setSelectedState("");
    setSelectedCity("");

    const country = countries.find((country) => country.name === countryName);
    if (country) {
      setStateOptions(country.states);
    } else {
      setStateOptions([]);
    }
  };

  const handleStateChange = (e) => {
    const stateName = e.target.value;
    setSelectedState(stateName);
    setSelectedCity("");

    const selectedCountryData = countries.find(
      (country) => country.name === selectedCountry
    );
    if (selectedCountryData) {
      const state = selectedCountryData.states.find(
        (state) => state.name === stateName
      );
      if (state) {
        setCityOptions(state.cities);
      } else {
        setCityOptions([]);
      }
    } else {
      setCityOptions([]);
    }
  };

  const setStateOptions = (states) => {
    // Update state options here
  };

  const setCityOptions = (cities) => {
    // Update city options here
  };
  const handleCountry = () => {
    setCountryArrow(!countryArrow);
  };
  const handleState = () => {
    setStateArrow(!stateArrow);
  };
  const handleCity = () => {
    setCityArrow(!cityArrow);
  };
  return (
    <>
      <div className="main-select">
        <img
          className="customSelect"
          src={DownArrow}
          style={{
            transform: countryArrow ? "rotate(180deg)" : "none",
            transition: "transform .3s ease-in-out",
          }}
        />
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
          className="formCountry"
          onClick={handleCountry}
          onBlur={() => setCountryArrow(false)}
        >
          <option value="" disabled>
            Country
          </option>
          {countries.map((country, index) => (
            <option key={index} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
      </div>

      <div className="main-select">
        <img
          className="customSelect"
          src={DownArrow}
          style={{
            transform: stateArrow ? "rotate(180deg)" : "none",
            transition: "transform .3s ease-in-out",
          }}
        />
        <select
          value={selectedState}
          onChange={handleStateChange}
          disabled={!selectedCountry}
          className="formState"
          onClick={handleState}
          onBlur={() => setStateArrow(false)}
        >
          <option value="" disabled>
            State
          </option>
          {selectedCountry &&
            countries
              .find((country) => country.name === selectedCountry)
              ?.states.map((state, index) => (
                <option key={index} value={state.name}>
                  {state.name}
                </option>
              ))}
        </select>
      </div>

      <div className="main-select">
        <img
          className="customSelect"
          src={DownArrow}
          style={{
            transform: cityArrow ? "rotate(180deg)" : "none",
            transition: "transform .3s ease-in-out",
          }}
        />
        <select
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          disabled={!selectedState}
          className="formCity"
          onClick={handleCity}
          onBlur={() => setCityArrow(false)}
        >
          <option value="" disabled>
            City
          </option>
          {selectedState &&
            countries
              .find((country) => country.name === selectedCountry)
              ?.states.find((state) => state.name === selectedState)
              ?.cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
        </select>
      </div>
    </>
  );
};
