import React from "react";
import phoneIcon from "../../assets/PhoneCall.png";
import mailIcon from "../../assets/EnvelopeSimple.png";
import person from "../../assets/Person.png";
import mail from "../../assets/mail.png";
import {
  ConnectSocialTitle,
  ContectBtn,
  FromDescription,
  FromEmail,
  FromMainTitle,
  FromPhone,
} from "../../content/product";
import { GetInTouch } from "./styled";
function ContactForm() {
  return (
    <GetInTouch className="website-container">
      <div className="touch-bg">
        <div className="table-content-wrapper">
          <div className="table-content">
            <div className="heading-getIn">
              <h3 className="text-getIn">{FromMainTitle}</h3>
              <p className="text-pere">{FromDescription}</p>
            </div>
            <div className="contect-area">
              <div className="contect-left">
                <div className="contectByMailAndPhone">
                  <div className="contectByPhone">
                    <img src={phoneIcon} alt="phone" />
                    <p>{FromPhone}</p>
                  </div>
                  <div className="contectByMail">
                    <img src={mailIcon} alt="mail" />
                    <p>{FromEmail}</p>
                  </div>
                </div>
                <div className="contectBySM">
                  <h6 className="smTitle">{ConnectSocialTitle}</h6>
                  {/* <div className="socialMedia">
                    <img src={faceBook} alt="facebook" />
                    <img src={linkdin} alt="linkdin" />
                    <img src={instragram} alt="instragram" />
                    <img src={twitter} alt="twitter" />
                  </div> */}
                </div>
              </div>
              <div className="contect-right">
                <div className="nameWrapper">
                  <input className="nameInput" placeholder="Full Name" />
                  <img src={person} className="personeImg" alt="Person icon" />

                </div>
                <div className="emailWrapper">
                  <input className="emailInput" placeholder="Email" />
                  <img src={mail} className="personeImg" alt="" />
                </div>
                <button className="contect-btn btn-hover">{ContectBtn}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GetInTouch>
  );
}

export default ContactForm;
