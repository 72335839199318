import compnyImage from "../assets/compnyImage.png";
import companyStoryImageUrl from "../assets/companyStoryImage.png";
import TeamGaneshRathore from "../assets/GaneshRathore.png"
import TeamAnilGehlot from "../assets/Anilgehlot.png"
import TeamRahulyadav from "../assets/Rahulyadav.png"
import TeamKrishnaDamderiya from "../assets/KrishnaDamderiya.png"
import TeamRaviSolanki from "../assets/RaviSolanki.png"
import TeamAnkitKumar from "../assets/AnkitKumar.png"
import SaumyaImage from "../assets/SaumyaImage.jpg"
import ViralImage from "../assets/ViralImage.jpg"

export const cardLable = "ABOUT COMPANY";
export const cardContent =
  "Creating bespoke Automation and Electronics product solutions for future-proofing the business";
export const contentBesideCard =
  "Cephlon Technology LLP is a leading designer, developer and manufacturer of different types of Automation and Electronics components and products, including PCBs, 3D models, MCU stacks, and even the integrated software programs. Our primary goal is to enrich the domestic and International market with innovative and agile products having unwavering quality and dedicated performance to meet the everyday business purpose.";
export const companyImageUrl = compnyImage;

export const misionLable = "OUR MISION";
export const misionTitle =
  "Turning the tide with advanced and innovative Automation and Electronics products";
export const misionContent =
  "Our mission is to leverage our years of knowledge and incorporate different market trends and technologies to design and manufacture top-notch Automation and Electronics products and business solutions.";

export const visionLable = "OUR VISION";
export const visionTitle =
  "Creating a digitally-enhanced future for Automation and Electronics markets";
export const visionContent =
  "We are dedicated to revolutionize the domestic and international Automation and Electronics markets by visualizing the ideas into real-time products and solutions through result-driven and data-backed approaches.";

export const foundersLable = "FOUNDING FATHERS OF CEPHLON";
export const foundersTitle =
  "Meet the men who changed the future of Automation and Electronics";
export const foundersContent =
  "Realizing the need for affordable, fast-paced, and high-performing Automation and Electronic products, our founding fathers conceptualized Cephlon.";

export const OurStoryLable = "OUR STORY";
export const OurStoryContent =
  "Follow us to know more about the path we took to turn the ball in our court and enhance Automation and Electronics markets";
export const companyStoryImage = companyStoryImageUrl;
export const OurStoryTitle =
  "We help our clients to visualize their ideas with precision";
export const OurStoryContentBesideTitle = (
  <>
    {" "}
    At Cephlon Technology LLP, we are concerned with helping<br/> our clients to develop their ideas into real-time products<br/> through 3D modeling technology. Our team conducts a<br/> thorough research on the requirements to ensure we can<br/> easily cater to our client’s needs from the very beginning of a<br/> project. 
  </>
);

export const foundersPost = [
  {
    imgUrl: SaumyaImage,
    name: "Saumya Gandhi",
    post: "Founder and CEO",
  },
  {
    imgUrl: ViralImage,
    name: "Viral Gandhi",
    post: "Founder",
  },
];

export const OurTeam = [
  {
    imgUrl: TeamAnilGehlot,
    name: "Anil Gehlot",
    post: "Manager",
  },
  {
    imgUrl: TeamAnkitKumar,
    name: "Ankit Kumar",
    post: "Engineer",
  },
  {
    imgUrl: TeamRaviSolanki,
    name: "Ravi Solanki",
    post: "Engineer",
  },
  {
    imgUrl: TeamKrishnaDamderiya,
    name: "Krishna Damderiya",
    post: "Engineer",
  },
  {
    imgUrl: TeamGaneshRathore,
    name: "Ganesh Rathore",
    post: "Assembely Manager",
  },
  
  {
    imgUrl: TeamRahulyadav,
    name: "Rahul Yadav",
    post: "Product Superdendent",
  },
  
  // {
  //   imgUrl: TeamRaviSolanki,
  //   name: "Ravi Solanki",
  //   post: "Engineer",
  // },
 
];
