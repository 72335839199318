import React, { useState, useRef, useEffect } from "react";
import loginImage from "../../assets/loginImg.png";
import { LoginStyled } from "./styled";
import tickMark from "../../assets/TickMark.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [isCheck, setIsCheck] = useState(false);
  const [apiStatus, setApiStatus] = useState(0);
  const [apiMsg, setApiMsg] = useState('');

  const username = useRef('')
  const Password = useRef('')
  
  const navigate = useNavigate();

  useEffect(() => {
    validatetoken()
  }, [])
  
  const validatetoken = () =>{
    fetch('https://api.cephlon.tech/Safco/validate_token?service=Cephlon', {
      method: 'POST',
      headers: {
        Accept: '*/*',
        Authorization : getCookie('token') 
      }
    }).then((response)=>{
      response.json().then((data)=>{
        if(data.Status === 'Successful'){
          navigate('/Console')
        }
      }).catch((error)=>{
        console.log(error)
      })
    }).catch((error)=>{
      console.log(error)
    })

  }

  function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) {
        return match[2];
      }
      else{
           return null;
      }
    }

  const handleClick = () => {
    setIsCheck(!isCheck)
  };

  const startLogin = () =>{
    setApiStatus(1)
    if(username.current === '' || username.current === null || Password.current === '' || Password.current === null){
      setApiMsg("Please enter your credentials.");
      setApiStatus(2);
    }else{
      fetch('https://api.cephlon.tech/Safco/signin/auth?username='+username.current+'&password='+Password.current+'&service=Cephlon', {
        method: 'POST',
        headers: {
          // "Access-Control-Allow-Origin":'*',
          // "Access-Control-Allow-Headers":"*",
          Accept: '*/*',
        }
      }).then((response)=>{
        response.json().then((data)=>{
          if(data.Status === "Successful"){
            let remember_status = "access"
            if(isCheck){
              remember_status = "offline"
            }
            fetch('https://api.cephlon.tech/Safco/signin/token?type='+remember_status, {
              method: 'POST',
              headers: {
                // "Access-Control-Allow-Origin":'*',
                // "Access-Control-Allow-Headers":"*",
                Accept: '*/*',
                Secret: data.Secret
              }
            }).then((response)=>{
              response.json().then((data)=>{
                if(data.Status === "Successful"){
                  var tokenRecd  = '';
                  console.log(data)
                  if(data["Access_Token"] != null){
                    tokenRecd = data["Access_Token"];
                  }else{
                    tokenRecd = data["Offline_Token"];
                  }
                  Cookies.set('token',tokenRecd,{expires:1,  sameSite:'Lax', secure:true});
                  setApiStatus(3)
                  setTimeout(()=>{
                    navigate('/Console')
                  }, [1000])
                }else{
                  setApiMsg('Login Error')
                  setApiStatus(2)    
                }
              }).catch((error)=>{
                setApiMsg('Json Error ::'+error)
                setApiStatus(2)  
              })
            }).catch((error)=>{
              setApiMsg('Network Error ::'+error)
              setApiStatus(2)
            })
          }else{
            setApiMsg("Email or Password is incorrect")
            setApiStatus(1)
          }
        }).catch((error)=>{
          setApiMsg('Json Error ::'+error)
          setApiStatus(2)  
        })
      }).catch((error)=>{
        setApiMsg('Network Error ::'+error)
        setApiStatus(2)
      })
    }
  }

  return (
    <LoginStyled>
      <div className="main-wrapper">
        <img src={loginImage} alt="loginImage" />

        <div className="form-wrapper">
          <div className="form">
            <h3 className="title">Sign in</h3>  {/* for an account */}
            {/* <h3 className="subTitle">Your social campaigns</h3> */}

            {/* <div className="social-wrapper">
              <div className="social-button">
                <img src={googleLogo} alt="googleLogo" />
              </div>
              <div className="social-button">
                <img src={facebookLogo} alt="facebookLogo" />
              </div>
            </div> */}

            {/* <div className="line">
              <h3 className="line-text">Or with email</h3>
            </div> */}

            <div className="form-input-wrapper">
              <input type="text" className="form-input" placeholder="Email" onChange={(data)=>{username.current=data.target.value}}/>
              <input
                type="password"
                className="form-input"
                placeholder="Password"
                onChange={(data)=>{Password.current=data.target.value}}
              />

              <div className="permission-wrapper">
                <div className="checkbox" onClick={handleClick}>{isCheck && <img src={tickMark} alt="tickMark" />}</div>
                <h3 className="permission-text" onClick={handleClick}>
                  {/* I Accept the <span>Terms</span> */}
                  Remember Me
                </h3>
              </div>

              <button className="btn btn-hover" onClick={startLogin}>Sign in</button>
              {
                apiStatus === 1 ?
                <div>Loading...</div>
                : apiStatus === 2?
                <div>{apiMsg}</div>
                : apiMsg === 3?
                <div>Successful</div>
                :null
              }
            </div>
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default Login;
