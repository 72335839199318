import React from "react";
import { ContactStyled } from "./styled";
import HigherOrderComp from "../HigherOrderComp";
import { contactDetail, contactText, description } from "../../content/contact";
import arrowRight from "../../assets/ArrowRight.png";
// import PartnerFormComp from "../PartnerForm";

const ContactUS = () => {
  return (
    <ContactStyled className="website-container">
      <form className="form">
        <div className="text-wrapper">
          <span className="text-gradient">{contactText}</span>
        </div>
        <h2 className="description">{description}</h2>
        <div className="contact-wrapper">
          {contactDetail.map((detail, index) => {
            return <ContactDetail key={index} {...detail} />;
          })}
        </div>
        <div className="field-wrapper">
          <div style={{ display: "flex", gap: "30px", width: "100%" }}>
            <InputField placeholder="Full Name" label="Name" style={{}} />
            <InputField placeholder="Email Address" label="Email" />
          </div>
          <InputField
            placeholder="Subjects"
            label="Subjects"
            style={{ maxWidth: "100%" }}
          />
          <InputField
            placeholder="Brand/Company/Product Name"
            label="Company Name"
            style={{ maxWidth: "100%" }}
          />
          <div className="input-wrapper" style={{ maxWidth: "100%" }}>
            <label className="input-label">Message</label>
            <textarea
              className="input-field"
              placeholder="Tell us about your project..."
              rows={5}
            />
          </div>
          <div className="gST-wrapper">
            {/* <p>GSTIN 23AARFC5621L1Z9</p> */}
            <button className="submit-btn btn-hover">
              Send Messages <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>
      </form>
      {/* <PartnerFormComp/> */}
    </ContactStyled>
  );
};

export default HigherOrderComp(ContactUS);

const ContactDetail = ({ iconUrl, title, value }) => {
  return (
    <div className="contact-detail">
      <div>
        <img src={iconUrl} alt={title} />
      </div>
      <div className="detail-wrapper">
        <div className="title">{title} </div>
        <div className="value">{value} </div>
      </div>
    </div>
  );
};

const InputField = ({ style, ...props }) => {
  return (
    <div className="input-wrapper" style={{ ...(style || {}) }}>
      <label className="input-label">{props?.label || "  "}</label>
      <input className="input-field" {...props} />
    </div>
  );
};
