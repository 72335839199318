import styled from "styled-components";

export const GetInTouch = styled.div`
margin-top: 100px;
  .touch-bg {
    background-color: #fafafa;
    padding: 60px 55px;
  }
  .table-content {
    background-color: #fff;
    height: 100%;
    border-radius: 20px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .table-content-wrapper {
    background: var(--gradient-color);
    padding: 1px;
    height: 100%;
    border-radius: 20px;
  }
  .heading-getIn {
    margin-bottom: 60px;
  }
  .heading-getIn .text-getIn {
    font-weight: 900;
    font-size: 38px;
    padding-bottom: 10px;
  }
  .heading-getIn .text-pere {
    font-size: 16px;
    font-weight: 500;
  }
  .contectByPhone {
    margin-bottom: 20px;
    font-size: 16px;
    color: #2d2d2d;
    font-weight: 500;
  }
  .contectByPhone,
  .contectByMail {
    display: flex;
    gap: 12px;
  }
  .contectByPhone p,
  .contectByMail p {
    margin: 0;
  }
  .contect-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contectBySM .socialMedia {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
  }
  .nameInput,
  .emailInput {
    width: 100%;
    border: solid 1px #d8d8d8;
    background-color: #fff;
    padding: 14px 20px 14px 60px;
    border-radius: 5px;
  }
  .contect-right .nameInput {
    margin-bottom: 10px;
  }
  .contect-right .contect-btn {
    background:var(--gradient-color) ;
    color: #fff;
    margin-top: 20px;
    padding: 16px 29px;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
  }
  .contect-left {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .contectBySM .smTitle {
    font-size: 21px;
    font-weight: 600;
    color: #2d2d2d;
    margin-bottom: 20px;
  }
  .personeImg {
    position: absolute;
    left: 20px;
    transform: translateY(83%);
  }
  .nameWrapper {
    position: relative;
    width: 100%;
  }

  .emailWrapper {
    position: relative;
    width: 100%;
  }
  .contect-right,
  .contect-left {
    flex-grow: 1;
  }
  .nameInput,
  .emailInput,
  .contect-btn {
    outline: none;
    cursor: pointer;
  }

  @media (min-width: 320px) {
    .contect-left {
      margin-bottom: 30px;
      align-items: center;
    }
    .table-content {
      padding: 16px;
    }
    .touch-bg {
      padding: 0px;
    }
  }
  @media (min-width: 561px) {
    .contect-left {
      align-items: unset;
    }
  }
  @media (min-width: 691px) {
    .contect-left {
      margin-bottom: 0px;
    }
    .table-content {
      padding: 60px;
    }
    .touch-bg {
      padding: 60px 55px;
    }
  }
`;
