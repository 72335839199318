import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Components/Home/index";
import { headerImageUrl, headerSubTitle, headerTitle } from "./content/home";
import ContactUS from "./Components/Contactus";
import AboutUs from "./Components/About/index";
import Product from "./Components/Product";
import PartnerFormComp from "./Components/PartnerForm";
import { productHeaderSubTitle, productHeaderTitle, productheaderImageUrl } from "./content/product";
import {
     aboutUrl,
     contactUrl,
     homeUrl,
     loginUrl,
     productUrl,
     serviceUrl,
     ResetPwdFromEmailUrl,
     ConsoleUrl,
     EmailVerificationUrl,
} from "./content/route";
import Login from "./Components/login";
import Services from "./Components/Services/index";
import ResetPwdFromEmail from "./Components/ResetPwdFromEmail";
import Console from "./Components/Console";
import EmailVerificationPage from "./Components/EmailVerificationPage";

const routes = [
     {
          path: "/",
          component: Home,
          props: {
               imageUrl: headerImageUrl,
               isHomePage: true,
               title: headerTitle,
               subtitle: headerSubTitle,
               button: true,
          },
     },
     {
          path: homeUrl,
          component: Home,
          props: {
               imageUrl: headerImageUrl,
               isHomePage: true,
               title: headerTitle,
               subtitle: headerSubTitle,
               button: true,
          },
     },
     {
          path: productUrl,
          component: Product,
          props: {
               imageUrl: productheaderImageUrl,
               isHomePage: false,
               title: productHeaderTitle,
               subtitle: productHeaderSubTitle,
               button: false,
          },
     },
     {
          path: contactUrl,
          component: ContactUS,
          props: {
               imageUrl: productheaderImageUrl,
               isHomePage: false,
               title: "",
               subtitle: "",
               button: false,
          },
     },
     {
          path: aboutUrl,
          component: AboutUs,
          props: {
               imageUrl: productheaderImageUrl,
               isHomePage: false,
               title: "",
               subtitle: "",
               button: false,
          },
     },
     {
          path: "/partner",
          component: PartnerFormComp,
          props: {
               imageUrl: productheaderImageUrl,
               isHomePage: false,
               title: "",
               subtitle: "",
               button: false,
          },
     },
     {
          path: serviceUrl,
          component: Services,
          props: {
               imageUrl: productheaderImageUrl,
               isHomePage: false,
               title: "Services",
               subtitle: headerSubTitle,
               button: false,
          },
     },
     {
          path: loginUrl,
          component: Login,
          props: {
               imageUrl: "",
               isHomePage: false,
               title: "",
               subtitle: "",
               button: false,
          },
     },
     {
          path: ResetPwdFromEmailUrl,
          component: ResetPwdFromEmail,
     },
     {
          path: ConsoleUrl,
          component: Console,
     },
     {
          path: EmailVerificationUrl,
          component: EmailVerificationPage,
     },
];

const AllRoutes = () => {
     return (
          <Routes>
               {/* <Route path={"/"}  /> */}
               {routes.map((route, index) => (
                    <Route path={route.path} key={index} element={<route.component {...(route?.props || {})} />} />
               ))}
          </Routes>
     );
};

export default AllRoutes;
