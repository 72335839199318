import React from 'react'
import HigherOrderComp from "../HigherOrderComp";
import { FirstTabination } from './styled';
import { servicesData } from '../../content/home';
import ContactForm from '../ContactForm';


function Services() {
  return (
    <>
      <FirstTabination>
        <div className="website-container">
          <div className="card-wrapper">
            {servicesData?.map((item, index) => (
              <div
                className="tabCard"
                style={{ cursor: "pointer" }}
                key={index}
                // onClick={() => handleOpen()}
              >
                <img
                  className="mainCardImg"
                  src={item.imgUrl}
                  alt={item.content}
                />
                <h2 className="cardTitle">{item.content}</h2>
                <p className="cardDescription">{item.descritpion}</p>
                {/* <div>
                  {item.button}{" "}
                  <img
                    className="arrowButton"
                    src={item.buttonIcon}
                    width={15}
                    height={15}
                    alt={item.title}
                  />
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </FirstTabination>
      <ContactForm />
    </>
  )
}

export default HigherOrderComp(Services)
