export const websiteUrl = "";
export const homeUrl = `${websiteUrl}/Home`;
export const productUrl = `${websiteUrl}/products`;
export const serviceUrl = `${websiteUrl}/services`;
export const contactUrl = `${websiteUrl}/contact`;
export const aboutUrl = `${websiteUrl}/about`;
export const partnerUrl = `${websiteUrl}/partner`;
export const loginUrl = `${websiteUrl}/login`;
export const ResetPwdFromEmailUrl = `${websiteUrl}/PwdEmailReset`;
export const ConsoleUrl = `${websiteUrl}/Console`;
export const EmailVerificationUrl = `${websiteUrl}/EmailVerification`;
