import styled from "styled-components";

export const ContactStyled = styled.div`
  position: relative;
  top: -130px;
  z-index: 1;
  .gST-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .form {
    width: 100%;
    padding: 45px 105px;
    border-radius: 10px;
    box-shadow: 34.9px 29.6px 48.3px 0 rgba(51, 102, 255, 0.05);
    background-color: #fff;
  }

  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 13px;
      letter-spacing: 1.63px;
      text-transform: uppercase;
    }
  }
  .input-label {
    font-weight: 500;
  }
  .description {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    color: #232536;
    margin: 12px 0 0;
    line-height: 40px;
  }
  .contact-wrapper {
    padding: 35px;
    border-top: 1px dashed #d5d7dd;
    border-bottom: 1px dashed #d5d7dd;
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    row-gap: 30px;
  }
  .contact-detail {
    border-right: 1px dashed #d5d7dd;
    display: flex;
    gap: 15px;
    padding: 0 30px;

    .title {
      opacity: 0.5;
      font-size: 13.2px;
      font-weight: 500;
      color: #595959;
    }
    .value {
      color: #000;
      font-weight: 500;
      margin-top: 5px;
      font-size: 14px;
    }
  }
  .contact-detail:last-child {
    padding-right: 0;
  }
  .detail-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .contact-detail:first-child {
    padding-left: unset;
  }
  .contact-detail:last-child {
    border: none;
  }

  .field-wrapper {
    display: flex;
    gap: 19px;
    flex-wrap: wrap;
  }

  .input-wrapper {
    max-width: 500px;
    width: 100%;
    flex-grow: 1;
  }
  .input-field {
    padding: 11.3px 0 11.1px 16.9px;
    border-radius: 4.7px;
    border: solid 0.9px #e6e8ec;
    background-color: #fff;
    color: #838e9e;
    width: 100%;
    color: #838e9e;
    outline: none;
    margin-top: 5px;
  }
  .submit-btn {
    padding: 0 30.1px;
    border-radius: 6.6px;
    background-image: var(--gradient-color);
    border: none;
    padding: 0 30px;
    height: 45px;
    color: white;
    margin-left: auto;
    outline: none;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 11px;
  }

  @media (max-width: 1156px) {
    .contact-wrapper {
      flex-direction: column;
      /* align-items: center; */
    }
    .contact-detail {
      padding: unset;
      border: none;
    }
  }
  @media (max-width: 768px) {
    .form,
    .contact-wrapper {
      padding: 16px;
    }
  }
  @media (max-width: 600px) {
    .input-wrapper {
      max-width: 100%;
    }
    .field-wrapper > div {
      flex-wrap: wrap;
    }
    .description {
      font-size: 20px;
      line-height: 1.5;
    }
  }
`;
